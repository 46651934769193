.navbar-container{
    width: 100%;
    position: fixed;
    right: 0;
    top: 0;

}

.navlink:hover{
    color: rgb(44, 139, 155);
}