.homepage-container{
    max-width: 800px;
    margin: 0 auto;
}
.header-container{
    max-width: 700px;
    /* margin: 2rem auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
}
.name-container{
    /* margin-bottom: 1rem; */
    text-align: center;
}
.image-container{
    max-width: 400px;
    height: auto;
    font-size: 1.2rem;
    text-align: center;
}
.bio-container{
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 2rem;
}
.chunk{
    margin-bottom: 1.5rem;
}
.info-container a{
    color: #269c6b !important;
    font-weight: 700;
}