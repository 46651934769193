.container{
    margin: 0 auto;
    max-width: 800px;
    min-height: 100vh;
}
.projects-info img{
    margin-top: 3rem;
    margin-bottom: 3rem;
    filter: drop-shadow(5px 5px 5px rgb(208, 216, 208));
}

.projects-info a{
    color: #269c6b !important;
    font-weight: 700;
}
